import React, { useEffect } from 'react';
import { BlockTitle, PriceWrap, StatusStyledChip, TripBox } from './styled';
import { isEmpty, upperFirst } from 'lodash';
import { Grid, Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { split } from 'lodash';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';

export const formatItalianNumber = (number) => {
  const formattedNumber = Math.floor(number).toLocaleString('it-IT');
  return formattedNumber;
};

export const getTime = (datetime) => {
  return split(datetime, ' ')[1];
};
export const getDate = (datetime) => {
  return dayjs(split(datetime, ' ')[0]).format('DD-MM-YYYY');
};

export const TripDetailSummary = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <>
      <BlockTitle>{t('TripDetail.Summary')}</BlockTitle>
      <TripBox>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="left"
              alignItems="left"
              sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  textAlign: 'left',
                }}>
                <Typography variant="h4" sx={{ padding: 0 }}>
                  {t('TripDetail.Price')}
                </Typography>
                <Typography variant="h2" noWrap>
                  {formatItalianNumber(
                    tripData?.price?.include_VAT
                      ? tripData?.price?.included_VAT_price_rounded
                      : tripData?.price?.included_price_rounded,
                  )}{' '}
                  €
                </Typography>
                <span style={{ textAlign: 'left', fontSize: '12px' }}>
                  {tripData?.price?.include_VAT
                    ? t('TripDetail.VATINCL', { vatper: 10 })
                    : t('TripDetail.VATEXCL', { vatper: 10 })}
                </span>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="left"
              alignItems="left"
              sx={{ height: '100%' }}>
              <PriceWrap>
                <Typography variant="h4" className="pl-0">
                  {t('TripDetail.RequestCode')}
                </Typography>
                <Typography variant="h2">{tripData?.trip_code}</Typography>
              </PriceWrap>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="flex-start" // Changed from 'left' to 'flex-start' for better CSS practice
              alignItems="flex-start" // Changed from 'left' to 'flex-start' for better CSS practice
              sx={{ height: '100%' }}>
              <Typography variant="h4" className="pl-0">
                {t('TripDetail.Status')}
              </Typography>
              <StatusChip
                status={tripData?.status}
                sx={{ display: 'inline-flex' }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack
              direction="column"
              spacing={2}
              justifyContent="left"
              alignItems="left"
              sx={{ height: '100%' }}>
              <Typography variant="h4" className="pl-0">
                {t('TripDetail.PassengerNumber')}
              </Typography>
              <Typography variant="h2" className="pl-0">
                {tripData?.passengers}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </TripBox>
    </>
  );
};

const StatusChip = ({ status }) => {
  const { t } = useTranslation();

  if (status == null) {
    return null;
  }

  return (
    <StatusStyledChip
      label={t(`General.${upperFirst(status)}`)}
      className={status}
    />
  );
};

export const TripItinerary = ({ tripData }) => {
  const { t } = useTranslation();

  return (
    <>
      <BlockTitle>{t('TripDetail.Itinerary')}</BlockTitle>
      <TripBox>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
          className="verticle_timeline_wrapper">
          {!isEmpty(tripData) &&
            tripData?.customer_segments.map((step, index) => (
              <TimelineItem key={index} className="verticle_timeline">
                <TimelineSeparator>
                  <TimelineDot sx={{ background: '#4f46e5' }} />

                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {/* tripData?.customer_segments[index - 1]['destination_stop']['arrival_datetime'] */}
                  {index !== 0 && (
                    <Typography>
                      {t('TripDetail.PointArrival', {
                        time: getTime(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime'],
                        ),
                        date: getDate(
                          tripData?.customer_segments[index - 1][
                            'destination_stop'
                          ]['arrival_datetime'],
                        ),
                      })}
                    </Typography>
                  )}

                  <Typography component="h3" className="steplabel">
                    {step?.departure_stop?.address}
                  </Typography>

                  <Typography>
                    {index === 0
                      ? t('TripDetail.StartDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime,
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime,
                          ),
                        })
                      : t('TripDetail.PointDepature', {
                          time: getTime(
                            step?.departure_stop?.departure_datetime,
                          ),
                          date: getDate(
                            step?.departure_stop?.departure_datetime,
                          ),
                        })}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          {!isEmpty(tripData) && tripData?.customer_segments && (
            <TimelineItem
              key={tripData?.customer_segments?.length + 1}
              className="verticle_timeline">
              <TimelineSeparator>
                <TimelineDot sx={{ background: '#4f46e5' }} />

                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography component="h3" className="steplabel">
                  {
                    tripData?.customer_segments[
                      tripData?.customer_segments?.length - 1
                    ]['destination_stop']['address']
                  }
                </Typography>
                <Typography>
                  {t('TripDetail.EndArrival', {
                    time: getTime(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime'],
                    ),
                    date: getDate(
                      tripData?.customer_segments[
                        tripData?.customer_segments?.length - 1
                      ]['destination_stop']['arrival_datetime'],
                    ),
                  })}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </TripBox>
    </>
  );
};

export default TripDetailSummary;
