import React, { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { BlockTitle, BackButton } from './styled';
import { ArrowBack } from '@mui/icons-material';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { getHistoryDetail } from '../../redux/reqhistory';
import { useNavigate, useParams } from 'react-router-dom';
import Chat from '../../components/Chat';
import TripStopsSummaryWithData from '../../components/TripSummary/dataWrapper';
import NextBestActionComponent from '../../components/NextBestActionComponent';
import { resetPriceCalculation } from '../../redux/pricecalc/slice';
import { resetReqHistoryInfo, reset } from '../../redux/reqhistory/slice';
import { useDispatch, useSelector } from 'react-redux';

const TripRequestConversation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestHistoryInfo } = useSelector((state) => state.reqhistory);
  const { userDetails } = useSelector((state) => state.auth);

  const { tripRequestCode } = useParams();

  const [reloadTrigger, setReloadTrigger] = useState(1);

  useEffect(() => {
    if (!isEmpty(tripRequestCode)) {
      const payload = {
        requestId: tripRequestCode,
        is_admin_user_flag: userDetails?.role == 'Admin' ? 1 : 0,
      };
      document.title = `${tripRequestCode} | ${t('General.Quotabus')}`;
      dispatch(getHistoryDetail(payload));
    }
    return () => {
      document.title = t('General.Quotabus');
      reset();
    };
  }, [dispatch, tripRequestCode, userDetails?.role]);

  useEffect(() => {
    return () => {
      dispatch(resetPriceCalculation());
      dispatch(resetReqHistoryInfo());
    };
  }, []);

  useEffect(() => {
    console.log(requestHistoryInfo);
  }, [requestHistoryInfo]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BackButton>
            <Button
              variant="contained"
              onClick={() => {
                const currentPath = window.location.pathname;
                const newPath = currentPath.replace(/\/conversation$/, ''); // Remove '/conversation' if present
                navigate(newPath);
              }}
              sx={{ marginX: '14px' }}>
              <ArrowBack />
              {t('PriceCalc.Button.Back')}
            </Button>
          </BackButton>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <NextBestActionComponent
            onStatusChange={() => window.location.reload()}
            status={requestHistoryInfo?.status}
            tripRequestId={requestHistoryInfo?.id}
          />
          <TripStopsSummaryWithData tripData={requestHistoryInfo} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box>
            <BlockTitle>{'Messaggi'}</BlockTitle>
          </Box>
          <Chat />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TripRequestConversation;
