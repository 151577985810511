import React from 'react';
import TripStopsSummary, { TripItinerary } from './index';
import CustomerInfo from '../CustomerInfo';

export const TripStopsSummaryWithData = ({ tripData }) => {
  return (
    <>
      {tripData && (
        <CustomerInfo
          firstName={tripData.client_name}
          surname={tripData.client_surname}
          clientType={tripData.client_type}
          companyAddress={tripData.company_address}
          companyNumber={tripData.company_number}
          companyVatNumber={tripData.company_vat_number}
          email={tripData.requestor_email}
        />
      )}
      <TripStopsSummary tripData={tripData} />
      <TripItinerary tripData={tripData} />
    </>
  );
};

export default TripStopsSummaryWithData;
