import React from 'react';
import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import ProfileDetailsComponent from '../../components/ProfileDetailsComponent/index.js';
import DepotsListComponent from '../../components/DepotsListComponent/index.js';
import EmailPersonalization from '../../components/CustomEmailComponent/index.js';
import CustomShareOfDrComponent from '../../components/CustomShareOfDrComponent/index.js';
import StripeAccount from '../../components/StripeAccount/index.js';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function a11yProps(language) {
  return {
    id: `offer-email-personalized-tab-${language}`,
    'aria-controls': `offer-email-personalized-tabpanel-${language}`,
  };
}

function TabPanel(props) {
  const { children, value, index, type, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${type}-${index}`}
      aria-labelledby={`tabpanel-${type}-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AdminOptions = () => {
  const { userDetails } = useSelector((state) => state.auth);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography
        component="h2"
        sx={{ fontSize: '20px', fontWeight: 700, letterSpacing: '-0.5px' }}>
        {'Admin Options'}
      </Typography>
      {userDetails?.role === 'Admin' && (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="offer email personalized by language">
            <Tab label="Italiano" {...a11yProps('IT')} />
            <Tab label="English" {...a11yProps('EN')} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <EmailPersonalization language="IT" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <EmailPersonalization language="EN" />
          </TabPanel>
        </>
      )}
      {userDetails?.role === 'Admin' && <CustomShareOfDrComponent />}
    </>
  );
};

const ProfiloPage = () => {
  return (
    <>
      <Container maxWidth="lg">
        <ProfileDetailsComponent />
        <StripeAccount />
        <DepotsListComponent />
        <AdminOptions />
      </Container>
    </>
  );
};

export default ProfiloPage;
