import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { EditButton, HeadPart, CustomEmailBox } from './styled';
import { CrossIcon, EditIcon, SaveIcon } from '../../assets/icon';
import { getConfig, updateConfig } from '../../redux/management/index.js';

const CustomShareOfDrComponent = ({ language }) => {
  const dispatch = useDispatch();
  const [isEditingOn, setIsEditingOn] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [shareOfDrMap, setShareOfDrMap] = useState({});

  const { config, updateConfigDetail } = useSelector(
    (state) => state.management,
  );

  useEffect(() => {
    setShareOfDrMap(config?.share_of_daily_rate_map);
  }, [config]);

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    setIsSaving(false);
  }, [updateConfigDetail]);

  return (
    <>
      <HeadPart>
        <div>
          <Typography component="h2">
            {'Share of Daily Rates Personalization'}
          </Typography>
        </div>
        <EditButton>
          {!isEditingOn ? (
            <Button variant="contained" onClick={() => setIsEditingOn(true)}>
              <EditIcon />
              {t(`General.Edit`)}
            </Button>
          ) : (
            <div>
              <Button
                disabled={isSaving}
                variant="contained"
                onClick={() => {
                  setIsSaving(true);
                  dispatch(
                    updateConfig({
                      ...config,
                      share_of_daily_rate_map: JSON.parse(shareOfDrMap),
                    }),
                  );
                }}
                className="save-btn">
                <SaveIcon />
                {t(`General.Save`)}
              </Button>
              <Button
                disabled={isSaving}
                variant="contained"
                onClick={() => {
                  setIsEditingOn(false);
                }}
                className="cancel">
                <CrossIcon />
                {t(`General.Cancel`)}
              </Button>
            </div>
          )}
        </EditButton>
      </HeadPart>
      <>
        {!isEditingOn ? (
          <CustomEmailBox>
            <Typography>
              {shareOfDrMap != null && typeof shareOfDrMap === 'string'
                ? shareOfDrMap
                : typeof shareOfDrMap === 'object'
                  ? JSON.stringify(shareOfDrMap)
                  : ''}
            </Typography>
          </CustomEmailBox>
        ) : (
          <TextField
            sx={{ width: '100%' }}
            placeholder={'Personalize the share of daily rates'}
            onChange={(e) => {
              setShareOfDrMap(e.target.value);
            }}
            value={
              shareOfDrMap != null && typeof shareOfDrMap === 'string'
                ? shareOfDrMap
                : typeof shareOfDrMap === 'object'
                  ? JSON.stringify(shareOfDrMap)
                  : ''
            }
          />
        )}
      </>
    </>
  );
};

export default CustomShareOfDrComponent;
