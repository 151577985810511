import React, { useEffect } from 'react';
import {
  PaperContainer,
  Header,
  Badge,
  Title,
  Description,
  StyledButton,
} from './styled';
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { updateRequestStatus } from '../../redux/reqhistory';
import { DoneAllOutlined } from '@mui/icons-material';

const NextBestActionComponent = ({ status, tripRequestId, onStatusChange }) => {
  let title, description;

  const { busPartner, userDetails } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleStatusChange = async (status) => {
    const payload = {
      is_admin_user_flag: userDetails?.role === 'Admin' ? 1 : 0,
      trip_request_id: tripRequestId,
      status: status,
    };
    await dispatch(updateRequestStatus(payload));
    // Perdoname madre por mi vida loca
    setTimeout(() => {
      onStatusChange();
    }, 1000);
  };

  switch (status) {
    case 'created':
      title = 'Prepara e invia il preventivo';
      description =
        'Utilizza il preventivatore per preparare e inviare una proposta dettagliata al cliente con tutte le informazioni necessarie per il viaggio.';
      break;
    case 'offered':
      title = 'In attesa della conferma del cliente';
      description =
        'Hai inviato il preventivo. Attendi la conferma del cliente e rimani disponibile per eventuali domande.';
      break;
    case 'accepted':
      title = 'Conferma disponibilità ed invia dettagli pagamento';
      description =
        'Il cliente ha accettato. Se non devi richiedere ulteriori informazioni al cliente, conferma la disponibilità dei mezzi ed invia i dettagli per effettuare il pagamento.';
      break;
    case 'confirmed':
      if (busPartner.bank_transfer_active === true) {
        title = 'Informazioni di pagamento inviate al cliente';
        description =
          '⁠Ricorda al cliente di effettuare il pagamento se non è ancora stato eseguito, oppure aggiorna lo stato della richiesta una volta ricevuto il pagamento.';
      } else {
        title = 'Richiedi il Pagamento';
        description =
          'La prenotazione è confermata. Usa la chat per invitare il cliente a procedere con il pagamento.';
      }
      break;
    case 'partpaid':
      title = 'Richiedi il Saldo Completo';
      description =
        'Hai ricevuto un pagamento parziale. Usa la chat per richiedere al cliente di completare il pagamento.';
      break;
    case 'paid':
      title = 'Organizza il Viaggio';
      description =
        'Il pagamento è stato ricevuto! Prepara la tua flotta e invia al cliente le informazioni finali tramite chat.';
      break;
    default:
      return null; // No component for other statuses
  }

  return (
    <PaperContainer elevation={0}>
      <Header>
        <Badge>
          <OfflineBoltOutlinedIcon />{' '}
          {/* Step 2: Include the icon in the Badge */}
          Azioni Consigliate
        </Badge>
      </Header>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {status === 'accepted' && busPartner.bank_transfer_active === true && (
        <StyledButton
          onClick={() => {
            handleStatusChange('confirmed');
          }}>
          <DoneAllOutlined /> {'Conferma Disponibilità'}
        </StyledButton>
      )}
    </PaperContainer>
  );
};

export default NextBestActionComponent;
